<template>
  <v-dialog v-model="show" width="700" persistent>
    <v-card>
      <v-card-title>
        <span>Recuperar cuenta</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-alert v-if="error" type="error" dense>{{ error }}</v-alert>
        <v-alert v-if="success" type="success" color="success darken-2" dense
          >Enlace de recuperación enviado correctamente. Puedes cerrar este
          diálogo.</v-alert
        >
        <p>
          A continuación, ingresa el correo electrónico con el que estás
          tratando de ingresar. Se te enviará un mensaje a este correo con un
          enlace de recuperación.
        </p>
        <p class="red--text">
          En caso que no lo encuentres en tu bandeja de entrada, revisa tu
          carpeta de SPAM.
        </p>
        <v-form v-model="isFormValid" ref="form" lazy-validation>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="formData.email"
                label="Correo electrónico"
                filled
                color="secondary"
                :rules="[formRules.required(), formRules.email()]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="secondary"
                :disabled="!isFormValid"
                :loading="loading"
                @click="sendRecoverRequest"
              >
                <v-icon small class="mr-2">mdi-send</v-icon>
                <span>Enviar correo de recuperación</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import formRules from "../../plugins/rules";
import serverRequestMixin from "../../mixins/serverRequest.mixin";
import axios from "axios";

export default {
  mixins: [serverRequestMixin],

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      formRules,
      isFormValid: false,
      formData: {
        email: "",
      },
      loading: false,
      error: "",
      success: false,
    };
  },

  methods: {
    async sendRecoverRequest() {
      this.loading = true;

      try {
        const serverResponse = await this.postRequest(
          "/passwordRecover/new",
          this.formData
        );

        await axios.post("https://api.emailjs.com/api/v1.0/email/send", {
          service_id: "soporte_service",
          template_id: "template_574k2ka",
          user_id: "DVvnsD_u_ap9t_uSb",
          accessToken: "firYJ_JRFbx0WrehbEfWQ",
          template_params: {
            user_email: this.formData.email,
            password_recover_url: `https://taller.t-panel.app/recover/${serverResponse.passRecover._id}`,
          },
        });

        this.success = true;
        this.clearForm();
      } catch (error) {
        if (error.data) this.error = error.data.message;
        else {
          console.error(error);
          this.error = "Error inesperado, favor de contactar con soporte";
        }
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.formData.email = "";
    },
  },
};
</script>
